;(function($) {
$(document).ready(function(){
	
	
	var newBrowser = false;
	if  (parseInt($.browser.version) != 7 && parseInt($.browser.version) != 8) { newBrowser = true; }
	var deviceAgent = navigator.userAgent.toLowerCase();
	var iosdetect = deviceAgent.match(/(iphone|ipod|ipad)/);
	if  (!newBrowser) {
		jQuery('HTML').addClass('oldIE');  
	}
	
	
	var ua = navigator.userAgent.toLowerCase(), 
	platform = navigator.platform.toLowerCase(), 
	UA = ua.match(/(opera|ie|firefox|chrome|version)[\s\/:]([\w\d\.]+)?.*?(safari|version[\s\/:]([\w\d\.]+)|$)/) || [null, 'unknown', 0], 
	mode = UA[1] == 'ie' && document.documentMode, 
	name = (UA[1] == 'version') ? UA[3] : UA[1], 
	version =  mode || parseFloat((UA[1] == 'opera' && UA[4]) ? UA[4] : UA[2]), 
	platform = ua.match(/ip(?:ad|od|hone)/) ? 'ios' : (ua.match(/(?:webos|android)/) || platform.match(/mac|win|linux/) || ['other'])[0]; 
	jQuery('HTML').addClass(name + version);  
	
	
	
	// Initialize colorbox
	$(".colorbox").colorbox({maxWidth: "90%", maxHeight: "90%", opacity:.6});
	$(".ext").colorbox({iframe:true, innerWidth:"90%", innerHeight:"90%", opacity:.6});
	$(".colorbox-inline").colorbox({maxWidth: "90%", maxHeight: "90%", opacity:.6, inline:true})

	// Hide all elements with the class of .hideme
	$('.hideme').hide();

	// Slider Toggle -- an anchor tag with class '.toggle' will expand it's href target.
	$(".toggle")
		.each( function() { $($(this).attr('href')).hide(); } )
		.toggle(
		function () {
			$(this).text($(this).text().replace("More", "Less"));
			$($(this).attr('href')).slideDown(300);
		},
		function () {
			$(this).text($(this).text().replace("Less", "More"));
			$($(this).attr('href')).slideUp(300);
		}
	);
	
	//tabs
	$('.single-tab').click(function(){
		$(this).siblings().removeClass('active-tab');
		$(this).addClass('active-tab');
		var newContentId = $(this).attr('id')+'-content';
		var newContentEq = $(this).index();
		$(this).parent().find('.tab-contents.active-content').removeClass('active-content').fadeOut('fast', function(){
			$(this).parent().find('.tab-contents').eq(newContentEq).fadeIn('fast').addClass('active-content');
		});
	});
	
	//accordion
	$(".accordion").accordion({ header: "h3.accordion-title" });
	$("h3.accordion-title").toggle(function(){
		$(this).addClass("active");
		}, function () {
		$(this).removeClass("active");
	});
	
	// Lightbox
	$("div.gallery-item .gallery-icon a").prepend('<span class="hover-image"></span>');
	$("div.gallery-item .gallery-icon a").attr("rel", "prettyPhoto[gallery]");
	var galleryimgWidth = $("div.gallery-item .gallery-icon img").width();
	var galleryimgHeight = $("div.gallery-item .gallery-icon img").height();
	$("div.gallery-item .gallery-icon .hover-image").css({"width": galleryimgWidth, "height": galleryimgHeight});
	$("div.gallery-item .gallery-icon a").css({"width": galleryimgWidth});
	$("a[rel^='prettyPhoto']").prettyPhoto({
		theme: 'light_square',
		animationSpeed: 'fast'
	});
	

	//Image Hover
	$('.hover-image, .hover-video').css({'opacity':'0'});
	$('.imagine, .post-thumbnail, .portfolio-thumbnail, .slide, div.gallery-item .gallery-icon').hover(
		function() {
			$(this).find('.hover-image, .hover-video').stop().fadeTo(750, 1);
			$(this).find("a[rel^='prettyPhoto'] .image, a[rel^='prettyPhoto'] .slider-image, a[rel^='prettyPhoto'] .attachment-thumbnail").stop().fadeTo(750, 0.5);
		},
		function() {
			$(this).find('.hover-image, .hover-video').stop().fadeTo(750, 0);
			$(this).find("a[rel^='prettyPhoto'] .image, a[rel^='prettyPhoto'] .slider-image, a[rel^='prettyPhoto'] .attachment-thumbnail").stop().fadeTo(750, 1);
		}
	);

	//Image Preloader
	$('.preload').hide();
	preloadint = setInterval("preloadImages(preloadi)",100);

	// Smooth Scrolling
    $('a[href*="#"]').smoothScroll();
	
	// Dynamically replace default "Your Name" with user's name (textchange function courtesy of ZURB: http://www.zurb.com/playground/jquery-text-change-custom-event)
	(function(a){a.event.special.textchange={setup:function(){a(this).bind("keyup.textchange",a.event.special.textchange.handler);a(this).bind("cut.textchange paste.textchange input.textchange",a.event.special.textchange.delayedHandler)},teardown:function(){a(this).unbind(".textchange")},handler:function(){a.event.special.textchange.triggerIfChanged(a(this))},delayedHandler:function(){var b=a(this);setTimeout(function(){a.event.special.textchange.triggerIfChanged(b)},25)},triggerIfChanged:function(b){var c=
	b.attr("contenteditable")?b.html():b.val();if(c!==b.data("lastValue")){b.trigger("textchange",b.data("lastValue"));b.data("lastValue",c)}}};a.event.special.hastext={setup:function(){a(this).bind("textchange",a.event.special.hastext.handler)},teardown:function(){a(this).unbind("textchange",a.event.special.hastext.handler)},handler:function(b,c){if((c===""||c===undefined)&&c!==a(this).val())a(this).trigger("hastext")}};a.event.special.notext={setup:function(){a(this).bind("textchange",a.event.special.notext.handler)},
	teardown:function(){a(this).unbind("textchange",a.event.special.notext.handler)},handler:function(b,c){a(this).val()===""&&a(this).val()!==c&&a(this).trigger("notext")}}})(jQuery);
	
	$('#author').bind('textchange', function (event, previousText) {
	  $('#authorname').text($(this).val());
	});
	
	// Adds URL Encoding and Decoding to JQuery (needed for setting gravatar default)
	$.extend({URLEncode:function(c){var o='';var x=0;c=c.toString();var r=/(^[a-zA-Z0-9_.]*)/;
	  while(x<c.length){var m=r.exec(c.substr(x));
	    if(m!=null && m.length>1 && m[1]!=''){o+=m[1];x+=m[1].length;
	    }else{if(c[x]==' ')o+='+';else{var d=c.charCodeAt(x);var h=d.toString(16);
	    o+='%'+(h.length<2?'0':'')+h.toUpperCase();}x++;}}return o;},
	URLDecode:function(s){var o=s;var binVal,t;var r=/(%[^%]{2})/;
	  while((m=r.exec(o))!=null && m.length>1 && m[1]!=''){b=parseInt(m[1].substr(1),16);
	  t=String.fromCharCode(b);o=o.replace(m[1],t);}return o;}
	});
	
	// Dynamically replace default gravatar with user's gravatar
	var gravatar_default = $.URLEncode("http://"+document.location.hostname+"/wp-content/themes/affilo/images/comments/gravatar.png");
	var gravatar_size = 60;
	$("#email").change(function() {
		$('#commentform .avatar').attr("src","http://www.gravatar.com/avatar/"+$.md5($('#email').val())+"?s="+gravatar_size+"&default="+gravatar_default);
	});
	

});
})(jQuery);

var preloadi = 0;
var preloadint = 0;
var preloadImages = function() {
	var images = jQuery('.preload').length;
	if (preloadi >= images) {
		clearInterval(preloadint);
	}
	jQuery('.preload:hidden').eq(0).fadeIn(400);
	preloadi++;
};